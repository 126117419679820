<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Read the following descriptions of the element bromine and indicate which are physical
        properties and which are chemical properties.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Property</th>
            <th style="font-size: 15px">Physical</th>
            <th style="font-size: 15px">Chemical</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex
                content="$\text{The density of the liquid at }20^{\circ}\text{C is } 3.12\text{g/mL}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{The liquid readily corrodes metals}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{Bromine is a reddish liquid that boils at }58.9^{\circ}\text{C and freezes at -} 7.2^{\circ}\text{C}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{It reacts rapidly with aluminum metal to form aluminum bromide}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question183',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
};
</script>
